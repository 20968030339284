<template>
    <div class="text-cell" v-html="sanitizedText">
    </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import { computed } from 'vue';

export default {
    name: "TextCell",
    props: {
        data: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
        }
    },
    setup(props) {
        const sanitizedText = computed(() =>  {
            if(!props.data.text){
                return '';
            }
            let text = sanitizeHtml(props.data.text,{
                // allowedAttributes: {
                //     a: [ 'href', 'name', 'target' ],
                //     img: [ 'src' ],
                //     table: [ 'style' ]
                // },
                allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
                allowedAttributes: {
                    '*': ['align', 'style',"id","class" ],
                    a: [ 'href', 'name', 'target','rel' ],
                    img: [ 'src','alt','width','height','loading' ],
                }
            });
            // let img = text.querySelector('img');
            // img.setAttribute('loading','lazy');

            return text;
        }
        )

        return {
            sanitizedText
        }
    }
}
</script>

<style scoped>
.text-cell ::v-deep(img){
    max-width: 100%;
    width: auto;
    height: auto;
}

</style>